import isString from 'lodash/isString'
import { ComponentProps, forwardRef } from 'react'
// eslint-disable-next-line settle/preferred-modules
import { TextField as MuiTextField } from '@mui/material'
import * as $ from './TextField.styled'

export interface ITextFieldProps extends Omit<ComponentProps<typeof MuiTextField>, 'size' | 'error'> {
  warning?: boolean
  error?: boolean | string
  size?: 'small' | 'medium' | 'large'
  'data-test-id'?: string
}

export const TextField = forwardRef<HTMLDivElement, ITextFieldProps>(
  ({ id, warning, children, size = 'medium', error, helperText, value, ...props }, ref) => (
    <$.TextField
      id={id}
      data-test-id={id}
      size={size}
      value={value}
      error={Boolean(error)}
      helperText={isString(error) ? error : helperText}
      {...(warning && { 'data-warning': true, warning })}
      {...props}
      onBlur={(e) => {
        const trimmedValue = e.target.value.trim()
        const event = {
          ...e,
          target: {
            ...e.target,
            value: trimmedValue,
          },
        }

        props.onBlur?.(event)

        if (value !== trimmedValue) {
          props.onChange?.(event)
        }
      }}
      ref={ref}
    >
      {children}
    </$.TextField>
  ),
)

TextField.displayName = 'TextField'
