import { Controller, ControllerProps, FieldValues } from 'react-hook-form'
import { PasswordInput, IPasswordInputProps } from 'settle-ui/components/PasswordInput/PasswordInput'

type TPasswordInputProps = Omit<IPasswordInputProps, 'inputRef'>
type TController<T extends FieldValues> = Pick<
  ControllerProps<T>,
  'defaultValue' | 'shouldUnregister' | 'name' | 'rules' | 'control'
>
export type TPasswordInputPropsControllerProps<T extends FieldValues> = TPasswordInputProps & TController<T>
export type TPasswordInputPropsController = <T extends FieldValues>(
  props: TPasswordInputPropsControllerProps<T>,
) => JSX.Element

// eslint-disable-next-line comma-spacing
export const PasswordInputController: TPasswordInputPropsController = ({
  name,
  rules,
  control,
  defaultValue,
  size = 'medium',
  shouldUnregister,
  required = true,
  margin = 'normal',
  color = 'secondary',
  showPasswordRequirements,
  ...restPasswordInputProps
}) => (
  <Controller
    defaultValue={defaultValue}
    shouldUnregister={shouldUnregister}
    name={name}
    rules={rules}
    control={control}
    render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => {
      const helperText = error ? error.message : restPasswordInputProps.helperText

      return (
        <PasswordInput
          {...fieldProps}
          {...restPasswordInputProps}
          size={size}
          color={color}
          inputRef={ref}
          margin={margin}
          required={required}
          helperText={helperText}
          error={Boolean(error) || restPasswordInputProps.error}
          showPasswordRequirements={showPasswordRequirements}
        />
      )
    }}
  />
)
