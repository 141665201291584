/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const AttentionItemBorderRadiusDesktop = '5px'
export const AttentionItemBorderRadiusMobile = '5px'
export const AttentionItemBorderWidthInReview = '0px'
export const AttentionItemBorderWidthRejected = '0px'
export const AttentionItemBorderWidthScheduled = '0px'
export const AttentionItemBorderWidthPaid = '0px'
export const AttentionItemBorderWidthFailed = '0px'
export const AttentionItemBorderWidthRefunded = '0px'
export const AttentionItemBorderWidthPartPaid = '0px'
export const AttentionItemColorUnscheduledBackground = '#eceff1'
export const AttentionItemColorUnscheduledText = '#37474f'
export const AttentionItemColorUnscheduledIcon = '#607d8b'
export const AttentionItemColorUnscheduledOutline = '#ffffff00'
export const AttentionItemColorInReviewBackground = '#e8eaf6'
export const AttentionItemColorInReviewText = '#283593'
export const AttentionItemColorInReviewIcon = '#3f51b5'
export const AttentionItemColorInReviewOutline = '#ffffff00'
export const AttentionItemColorApprovedBackground = '#f1f8e9'
export const AttentionItemColorApprovedText = '#33691e'
export const AttentionItemColorApprovedIcon = '#8bc34a'
export const AttentionItemColorApprovedOutline = '#ffffff00'
export const AttentionItemColorRejectedBackground = '#fbe9e7'
export const AttentionItemColorRejectedText = '#d84315'
export const AttentionItemColorRejectedIcon = '#f4511e'
export const AttentionItemColorRejectedOutline = '#ffffff00'
export const AttentionItemColorScheduledBackground = '#e3f2fd'
export const AttentionItemColorScheduledText = '#1565c0'
export const AttentionItemColorScheduledIcon = '#2196f3'
export const AttentionItemColorScheduledOutline = '#ffffff00'
export const AttentionItemColorProcessedBackground = '#f1f8e9'
export const AttentionItemColorProcessedText = '#33691e'
export const AttentionItemColorProcessedIcon = '#8bc34a'
export const AttentionItemColorProcessedOutline = '#ffffff00'
export const AttentionItemColorPaidBackground = '#f1f8e9'
export const AttentionItemColorPaidText = '#33691e'
export const AttentionItemColorPaidIcon = '#8bc34a'
export const AttentionItemColorPaidOutline = '#ffffff00'
export const AttentionItemColorFailedBackground = '#fbe9e7'
export const AttentionItemColorFailedText = '#d84315'
export const AttentionItemColorFailedIcon = '#f4511e'
export const AttentionItemColorFailedOutline = '#ffffff00'
export const AttentionItemColorRefundedBackground = '#eceff1'
export const AttentionItemColorRefundedText = '#37474f'
export const AttentionItemColorRefundedIcon = '#607d8b'
export const AttentionItemColorRefundedOutline = '#ffffff00'
export const AttentionItemColorPartPaidBackground = '#fff3e0'
export const AttentionItemColorPartPaidText = '#ef6c00'
export const AttentionItemColorPartPaidIcon = '#ff9800'
export const AttentionItemColorPartPaidOutline = '#ffffff00'
