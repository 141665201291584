export interface IGetPasswordStrength {
  hasNumber: boolean
  hasLetters: boolean
  hasLowercase: boolean
  hasUppercase: boolean
  hasCharLength: boolean
  hasSpecialChar: boolean
  isStrong: boolean
}

export const getPasswordStrength = (value: string): IGetPasswordStrength => {
  const hasNumber = /\d/.test(value)
  const hasLetters = /[a-zA-Z]/.test(value)
  const hasLowercase = /[a-z]/.test(value)
  const hasUppercase = /[A-Z]/.test(value)
  const hasCharLength = /^.{8,}$/.test(value)
  const hasSpecialChar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)

  return {
    hasNumber,
    hasLetters,
    hasLowercase,
    hasUppercase,
    hasCharLength,
    hasSpecialChar,
    isStrong: hasNumber && hasLetters && hasLowercase && hasUppercase && hasCharLength && hasSpecialChar,
  }
}
