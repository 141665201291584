import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getPasswordStrength } from 'lib/helpers/getPasswordStrength/getPasswordStrength'
import { PasswordRequirementItem } from './components/PasswordRequirementItem/PasswordRequirementItem'
import * as $ from './PasswordRequirements.styled'

interface IPasswordRequirementsProps {
  value: Maybe<string>
  error: boolean
}
export const PasswordRequirements: React.FC<IPasswordRequirementsProps> = ({ value, error }) => {
  const { t } = useTranslation()
  const { hasNumber, hasLetters, hasLowercase, hasUppercase, hasCharLength, hasSpecialChar } = useMemo(
    () => getPasswordStrength(value ?? ''),
    [value],
  )

  return (
    <$.Root data-test-id="password-requirements-root">
      <PasswordRequirementItem passed={hasCharLength} error={error}>
        {t('passwordRequirements.length')}
      </PasswordRequirementItem>
      <PasswordRequirementItem passed={hasLetters && hasNumber && hasSpecialChar} error={error}>
        {t('passwordRequirements.lettersNumbersSpecial')}
      </PasswordRequirementItem>
      <PasswordRequirementItem passed={hasLowercase && hasUppercase} error={error}>
        {t('passwordRequirements.lowerUpper')}
      </PasswordRequirementItem>
    </$.Root>
  )
}
