import { styled } from '@mui/material'
import { MessageColorInfoBackground, MessageColorInfoOnBackground } from 'settle-ui/components/Message/Message.tokens'

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: MessageColorInfoOnBackground,
  backgroundColor: MessageColorInfoBackground,
  ...Object(theme.typography.caption),
  padding: theme.spacing(1.25, 1.5),
  margin: theme.spacing(1.5, 0, 0.5, 0),
  borderRadius: theme.spacing(0.5),
}))

export const FormControl = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: MessageColorInfoOnBackground,
  backgroundColor: MessageColorInfoBackground,
  ...Object(theme.typography.caption),
  padding: theme.spacing(1.25, 1.5),
  margin: theme.spacing(1.5, 0, 0.5, 0),
  borderRadius: theme.spacing(0.5),
}))
