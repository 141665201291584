import * as $ from './PasswordRequirementItem.styled'

interface IPasswordRequirementItemProps {
  passed: boolean
  error: boolean
}
export const PasswordRequirementItem: Settle.FC<IPasswordRequirementItemProps> = ({ children, passed, error }) => (
  <$.Check error={!passed && error} data-test-id="check-item-root">
    {passed && <$.CheckPassedIcon data-test-id="passed-icon" />}
    {!passed && !error && <$.CircleIcon data-test-id="neutral-icon" />}
    {!passed && error && <$.ErrorIcon data-test-id="error-icon" />}
    {children}
  </$.Check>
)
