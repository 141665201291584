import { styled, InputAdornment } from '@mui/material'
import { IconButton } from 'settle-ui/components/IconButton/IconButton'
import { styledWithSelector } from 'lib/helpers/styledWithSelector/styledWithSelector'

export const AutocompleteInputAdornment = styled(InputAdornment)({
  position: 'absolute',
  right: 6,
})

export const EditButton = styledWithSelector(IconButton)(() => ({
  display: 'none',
}))
